import { IQueryResult } from "src/models";
import { IServiceError } from "src/models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export type ISchemaMetadata = {
  catalog: string;
  schema: string;
};

export async function getSchemasForConnection(connectionName: string) {
  const rawData = await cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/schemas?catalogName=${encodeURIComponent(connectionName)}`,
  });

  if ("error" in rawData) {
    throw new Error(
      rawData.error?.message ??
        `An error ocurred loading the schema metadata for connection = ${connectionName}`,
    );
  }

  const metadata: ISchemaMetadata[] =
    "results" in rawData && rawData?.results?.[0]?.rows != null
      ? rawData.results[0].rows.map<ISchemaMetadata>((r) => {
          return {
            catalog: r[0],
            schema: r[1],
          };
        })
      : [];

  return metadata;
}
