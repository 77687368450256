import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useState } from "react";
import { Calendar } from "react-date-range";
import { IHourMinuteSecond } from "./types/IHourMinuteSecond";
import { Col, Row } from "reactstrap";
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Popper,
} from "@mui/material";
import { CDataSelect, CDataSelectProps } from "../select/CDataSelect";
import { CDataTypography } from "../text/CDataTypography";
import { format, startOfDay } from "date-fns";
import { TimeSelector } from "./TimeSelector";
import { ButtonType, CDataButton } from "../buttons/CDataButton";
import "./CDataDatePicker.scss";

export type CDataDatePickerProps = {
  date: Date | undefined;
  onChange: (date: Date | undefined) => void;
  allowPreviousDates?: boolean;
  showClearButton?: boolean;
  showTimePicker?: boolean;
  selectProps?: Partial<CDataSelectProps>;
  helperText?: string;
  name?: string;
  invalid?: boolean;
  selectedDateDisplay?: "numeric" | "calendar";
};

export function CDataDatePicker(props: CDataDatePickerProps) {
  const {
    date,
    onChange,
    selectProps,
    allowPreviousDates = true,
    showClearButton = true,
    showTimePicker = true,
    helperText,
    name,
    invalid,
    selectedDateDisplay = "numeric",
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const time: IHourMinuteSecond = {
    hour: date?.getHours() ?? 0,
    minute: date?.getMinutes() ?? 0,
    second: date?.getSeconds() ?? 0,
  };

  const handleClear = () => {
    onChange(undefined);
    setOpen(false);
  };

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);
  const seconds = minutes;

  const handleTimeChange = (
    field: "hour" | "minute" | "second",
    value: number,
  ) => {
    const newDate =
      date != null ? new Date(date.getTime()) : startOfDay(new Date());

    if (field === "hour") {
      newDate.setHours(value);
    } else if (field === "minute") {
      newDate.setMinutes(value);
    } else if (field === "second") {
      newDate.setSeconds(value);
    }

    onChange(newDate);
  };

  const getChosenDateText = () => {
    if (!date) return "";
    const timeFormat = showTimePicker ? "HH:mm:ss" : "";

    if (selectedDateDisplay === "numeric") {
      return format(date, `yyyy-MM-dd ${timeFormat}`);
    } else {
      return format(date, `MMM d, yyyy ${timeFormat}`);
    }
  };

  return (
    <Col className="component-CDataDatePicker">
      <ClickAwayListener
        mouseEvent="onMouseUp" // This prevents the popper from closing when opening a time selector
        onClickAway={() => setOpen(false)}
      >
        <div className="w-100">
          <CDataSelect
            name={name ?? undefined}
            className={invalid ? "red-focus" : ""}
            onClick={(event) => {
              setOpen((prev) => !prev);
              setAnchorEl(event.currentTarget);
            }}
            open={false} // We're using the popper as the actual contents here, so we don't ever want to actually open this selector
            placeholder="Date Range"
            endAdornment={<i className="fa fa-solid fa-calendar-day" />}
            IconComponent={"span"}
            renderValue={() => {
              if (!date) {
                return undefined;
              } else {
                return (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                  >
                    <CDataTypography>{getChosenDateText()}</CDataTypography>
                    {showClearButton && (
                      <ButtonBase
                        aria-label={"Clear date-time range"}
                        // This stops the MUI dropdown from picking up the click on the X button and opening the dropdown
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClear();
                        }}
                        className="date-picker-x-button m-0"
                      >
                        <i className="fa fa-times fa-solid align-content-center p-0" />
                      </ButtonBase>
                    )}
                  </Box>
                );
              }
            }}
            value={""}
            {...selectProps}
          />

          <Popper
            anchorEl={anchorEl}
            className="component-CDataDatePicker-popper"
            open={open}
            placement="bottom-start"
            modifiers={[
              {
                // This prevents the date picker from going below the bottom of the screen
                // when displayed in a modal dialog.
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: "document",
                  padding: 120,
                },
              },
            ]}
          >
            <div className="date-picker-contents mt-1">
              <Row>
                <Col>
                  <Calendar
                    date={date}
                    monthDisplayFormat="MMMM yyyy"
                    onChange={(newDate) => onChange(newDate)}
                    preventSnapRefocus={true}
                    showPreview={true}
                    weekdayDisplayFormat="EEEEEE" // Two letter abbreviations
                    minDate={allowPreviousDates ? undefined : new Date()}
                  />
                </Col>
              </Row>
              {showTimePicker && (
                <Row className="mb-3">
                  <Col className="time-selector-column">
                    <TimeSelector
                      options={hours}
                      onChange={(value) => handleTimeChange("hour", value)}
                      value={time.hour}
                    />
                    {" : "}
                    <TimeSelector
                      options={minutes}
                      onChange={(value) => handleTimeChange("minute", value)}
                      value={time.minute}
                    />
                    {" : "}
                    <TimeSelector
                      options={seconds}
                      onChange={(value) => handleTimeChange("second", value)}
                      value={time.second}
                    />
                  </Col>
                </Row>
              )}
              <Divider
                aria-hidden="true"
                sx={{ borderBottom: "2px solid #DAE0E6" }}
              />
              <Row>
                <Col className="d-flex justify-content-end m-3 gap-2">
                  {showClearButton ? (
                    <CDataButton
                      buttonType={ButtonType.Borderless}
                      onClick={handleClear}
                    >
                      Clear
                    </CDataButton>
                  ) : (
                    <CDataButton
                      buttonType={ButtonType.Borderless}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </CDataButton>
                  )}
                  <CDataButton
                    buttonType={ButtonType.Primary}
                    onClick={() => setOpen(false)}
                  >
                    Done
                  </CDataButton>
                </Col>
              </Row>
            </div>
          </Popper>
          {helperText && (
            <CDataTypography
              variant="typography-variant-helper-text"
              color={
                invalid
                  ? "typography-color-danger"
                  : "typography-color-text-regular"
              }
            >
              {helperText}
            </CDataTypography>
          )}
        </div>
      </ClickAwayListener>
    </Col>
  );
}
